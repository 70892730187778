import React from 'react'

import { Helmet } from 'react-helmet'

import styles from './home.module.css'

const Home = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>MV2A - Architectual Studio</title>
        <meta property="og:title" content="MV2A - Architectual Studio" />
      </Helmet>
      <div className={styles['container1']}>
        <div className={styles['container2']}>
          <div className={styles['FeatureCard']}>
            <img
              alt="image"
              src="/playground_assets/mv2a-logo-white.svg"
              className={styles['image']}
            />
          </div>
        </div>
        <footer className={styles['Footer']}>
          <span className={styles['text']}>+38267514183 | info@mv2a.com</span>
        </footer>
      </div>
    </div>
  )
}

export default Home
